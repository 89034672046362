import React from "react";

export default function About() {
  return (
    <div className="container py-5 " style={{ marginTop: "77px" }}>
      <div className="py-4">
        <h4 className="text-center">About us</h4>
        <h2 className="text-center">WHERE YOU CAN FIND US</h2>
      </div>
      <hr />
      <div className="d-flex justify-content-between py-5 text-center">
        great model for About Us pages for small businesses. It provides a nice
        company overview, including a How We Work to give site visitors an
        inside look at operations. 40 Colori’s page holds promise to readers,
        telling a coherent story that increases trust and transparency. How We
        Work to give site visitors an inside look at operations. 40 Colori’s
        page holds promise to readers, telling a coherent story that increases
        trust and transparency.
      </div>
      <div className=" py-0 text-center">
        great model for About Us pages for small businesses. It provides a nice
        company overview, including a How We Work to give site visitors an
      </div>
    </div>
  );
}
